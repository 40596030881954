<template>
  <div class="product-container">
    <div class="product-image">
      <van-image
        width="100%"
        :src="image"
        @click="onClickDetail"
        fit="contain"
      />
    </div>
    <div class="goods-info-container">
      <div class="goods-title">
        <!--        暂时去掉tag-->
        <!--        <template v-for="tag in tags">-->
        <!--          <span class="product-tag" :key="tag" v-text="tag"></span>-->
        <!--        </template>-->
        <span class="product-title" @click="onClickDetail">{{ title }}</span>
      </div>
    </div>
    <div class="price-container">
      <price-text :price="price" />
      <!-- <van-button
        size="mini"
        @click="onClick"
        round
        text="去购买"
      ></van-button> -->
    </div>
  </div>
</template>

<script>
import PriceText from '@/components/price-text.vue'

export default {
  name: 'ProductCard',
  components: { PriceText },
  props: {
    image: { type: String },
    title: { type: String },
    tags: {
      type: Array,
    },
    skuCode: {
      type: String,
      default: null,
    },
    price: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  emits: ['purchase'],
  methods: {
    onClick() {
      this.$emit('purchase')
    },
    onClickDetail() {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          skuCode: this.skuCode,
          accountType: 'ACC_CASH_BAL',
          coverImage: this.image,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.product-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .product-image {
    border-radius: 8px 8px 0 0;
  }
  /deep/ .van-image {
    height: 174px;
    background-image: url('../../assets/home/goods-img-shadow.png');
    background-size: contain;
  }

  /deep/ .van-image__img {
    border-radius: 6px 6px 0 0;
  }

  .goods-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: calc(100% - 170px);
    padding: 12px 12px 0;
    box-sizing: border-box;
    -webkit-flex: 1;
    flex: 1;

    .goods-title {
      // margin: 12px 12px 6px 12px;
      flex-grow: 1;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      // height: 42px;
      line-height: 20px;

      .product-tag {
        font-size: 10px;
        font-weight: 500;
        color: @white;
        line-height: 14px;
        border-radius: 2px;
        background-color: @primary-color;
        padding: 1px 5px;
        margin-right: 2px;
        margin-top: 2px;
        margin-bottom: 2px;
        display: inline-block;
      }

      .product-title {
        font-size: 14px;
        font-weight: 500;
        color: @text-color-title;
        word-wrap: break-word;
        word-break: break-all;
        line-height: 20px;
      }
    }

    .van-button--default /deep/ {
      color: @primary-color;
      border: 1px solid #f7ecec;
      width: 60px;
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .price-container {
    padding: 0 12px 12px;
    display: flex;
    height: 30px;
    justify-content: space-between;
    //  margin: 0 12px 15px 12px;
    align-items: center;

    .product-price {
      height: 25px;
      font-size: 16px;
      font-weight: 500;
      color: @primary-color;
      line-height: 25px;
    }

    .product-price::first-letter {
      font-size: 12px;
      margin-right: 4px;
    }
  }
}
</style>
