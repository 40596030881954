import { Toast } from 'vant'
import moment from 'moment'
import * as CryptoJS from 'crypto-js'
import _ from 'lodash'

export function fileDownload( // 流文件下载
  data,
  fileName,
  type = 'application/vnd.ms-excel'
) {
  const blob = data
  const link = document.createElement('a')
  link.href = URL.createObjectURL(new Blob([blob], { type }))
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function copyText(text) {
  // 通用复制方法
  console.log('copy', text)
  var input = document.createElement('input') // 直接构建input
  input.value = text // 设置内容
  document.body.appendChild(input) // 添加临时实例
  input.select() // 选择实例内容
  document.execCommand('Copy') // 执行复制
  document.body.removeChild(input) // 删除临时实例
  Toast.success('复制成功 !')
}

// 表单序列化
export const serialize = (data) => {
  const list = []
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}

export function getQueryString(url, paraName) {
  const arrObj = url.split('?')
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&')
    let arr
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')
      // eslint-disable-next-line eqeqeq
      if (arr != null && arr[0] == paraName) {
        return arr[1]
      }
    }
    return ''
  } else {
    return ''
  }
}

// 判断是否为空
export function validatenull(val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (
      val === 'null' ||
      val == null ||
      val === 'undefined' ||
      val === undefined ||
      val === ''
    )
      return true
    return false
  }
  return false
}

/**
 *加密处理
 */
export const encryption = (params) => {
  let { data, type, param, key } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach((ele) => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

// 身份证号码验证
export const validataIdentity = (params) => {
  // const date = new Date()
  // const year = date.getFullYear().toString()
  const year = moment().format('YYYY')
  const oneYear = year.substring(3, 2)
  let lastYear = year.substring(4, 3)
  if (params.substring(8, 9) === oneYear) {
    lastYear === 0 ? '0' : `0-${lastYear}`
  } else {
    lastYear = '0-9'
  }
  console.log(oneYear, lastYear)
  console.log(params)
  const regStr =
    '^[1-9][0-9]{5}' +
    '([1][9][0-9]{2}|[2][0][0|1|' +
    oneYear +
    '][' +
    lastYear +
    '])' +
    '([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$'
  const petten = new RegExp(regStr)
  return petten.test(params)
}

// 手机号码验证
export const validataPhone = (params) => {
  if (params.substring(0, 3) === '+86') {
    params = params.replace('+86', '')
  } else if (params.substring(0, 2) === '86') {
    params = params.replace('86', '')
  }
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(params)
}

export const validNumber = (params) => {
  return /^\d+$/.test(params.replace(/(\s+)/g, ''))
}

// 邮箱验证
export const validataEmail = (params) => {
  return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(
    params
  )
}

// 验证密码格式必须在6-20位 数字和英文
export const validataPassword = (params) => {
  // 6-20位 数字和英文
  // const reg = /^(?=.*[a-zA-Z])(?=.*[0-9])(\w){6,20}$/
  // 密码至少6位
  const reg = /[0-9a-zA-Z]{6,20}$/
  return reg.test(params)
}

// 验证数字和英文
export const validataReg = (params) => {
  const reg = /^[0-9a-zA-Z]+$/
  return reg.test(params)
}

// 获取商品限购信息
export const getLimitInfo = (goodsInfo) => {
  if (_.isEmpty(goodsInfo)) {
    return null
  }

  // 是否有限购
  if (_.isEmpty(goodsInfo.hasPrepare)) {
    return null
  }
  // 是否有限购
  if (goodsInfo.hasPrepare === 'N') {
    return null
  }

  // 限购方式是否为不限购
  if (goodsInfo.limitType === 'unlimit') {
    return null
  }

  const limitInfo = {
    text: null,
    limit: null,
    period: false,
  }
  if (!['stock', 'order'].includes(goodsInfo.limitMode)) {
    return null
  }
  // 限购方式
  limitInfo.text = goodsInfo.limitMode === 'order' ? '限购' : '最多库存'
  // 是否期间限购
  // 期间限购为 personPeriod shopPeriod shopkeeperPeriod
  if (['person', 'shop', 'shopkeeper'].includes(goodsInfo.limitType)) {
    limitInfo.text = `${limitInfo.text}${goodsInfo.limit}件`
  } else {
    const limitPeriod = { D: '每日', W: '每周', M: '每月', Q: '每季' }
    limitInfo.text = `${limitPeriod[goodsInfo.limitPeriod]}${limitInfo.text}${
      goodsInfo.limit
    }件`
    limitInfo.period = true
  }
  limitInfo.limit = goodsInfo.limit
  return limitInfo
}

// 是否为微信环境打开
export function isWeChat() {
  const userAgent = navigator.userAgent.toLowerCase()
  // 排除企业微信
  return /micromessenger/.test(userAgent) && !userAgent.includes('wxwork')
}

export function isWorkWechat() {
  //获取ua标识头
  const ua = window.navigator.userAgent.toLowerCase()
  //判断ua和微信浏览器的标识头是否匹配
  return /micromessenger/.test(ua) && ua.includes('wxwork')
}
