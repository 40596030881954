<template>
  <div class="recommend-container">
    <div class="recommend-title">新品推荐</div>
    <van-list
      v-model="loading"
      ref="scroll"
      :finished="finished"
      @load="getList"
    >
      <van-grid :column-num="1" :border="false">
        <van-grid-item v-for="(product, index) in list" :key="index">
          <product-card
            :image="product.coverImage"
            :title="product.skuName"
            :tags="product.tagNames"
            :price="product.price"
            :sku-code="product.skuCode"
            @purchase="purchase(index)"
          ></product-card>
        </van-grid-item>
      </van-grid>
      <van-divider v-show="finished">{{ finishedText }}</van-divider>
    </van-list>
    <van-popup
      v-model="showPurchase"
      v-if="showPurchase"
      round
      position="bottom"
    >
      <purchase-card
        :id="selectedProduct.id"
        :title="selectedProduct.skuName"
        :price="selectedProduct.price"
        :unit="selectedProduct.packageUnit"
        :thumb="selectedProduct.coverImage"
        :buy-cloud="selectedProduct.buyCloud === 'Y'"
        :buy-home="selectedProduct.buyHome === 'Y'"
        :sale-channel="saleChannel"
        :goods-info="selectedProduct"
        @buy="toBuy"
        @cancle="toCancle"
      />
    </van-popup>
  </div>
</template>

<script>
import ProductCard from './productCard.vue'
import PurchaseCard from './purchaseCard.vue'
import { getNewGoods } from './api/home.api'
import List from '@/mixins/list'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { getGoodsInfo } from '@/views/goodsDetail/api'
import { getStore, setStore } from '@/utils/store'
import { SaleChannelKey } from '@/const/common'
import { Toast } from 'vant'
import { CustomerStatusConst, StatusConst } from '@/views/login/const'
import { getCustomerInfo } from '@/service/common'

export default {
  components: { ProductCard, PurchaseCard },
  mixins: [List],
  data() {
    return {
      showPurchase: false,
      list: [],
      // pageSize: 10,
      // currentPage: 0,
      selectedProduct: null,
      finishedText: '已经到底了哦～',
      // customerInfo: JSON.parse(sessionStorage.getItem('customerInfo')).content,
      //账户类型列表
      accountTypes: [],
      saleChannel: '',
      yhno: '',
    }
  },
  computed: {
    ...mapGetters([
      'getAccountType',
      'getUnitsList',
      'customerInfo',
      'userInfo',
      'saleMode',
      'currentSelectRole',
    ]),
  },
  mounted() {
    // this.customerInfo.custCode='CM00000019'
    this.yhno = getStore({ name: 'yhno' })
    this.saleChannel = sessionStorage.getItem(SaleChannelKey)
    this.getAccountDict()
    this.getUnitDict()
  },
  methods: {
    //获取字典
    async getAccountDict() {
      if (!_.isEmpty(this.getAccountType)) {
        this.accountTypes = this.getAccountType
      } else {
        await this.$store.dispatch('CustomerType').then((res) => {
          this.accountTypes = res
        })
      }
    },
    //获取字典
    async getUnitDict() {
      if (this.getUnitsList) return
      await this.$store.dispatch('UnitType')
    },
    listApi() {
      return getNewGoods({
        custCode: this.customerInfo.custCode,
        yhno: this.yhno,
        companyPartnerType: this.saleMode
          ? this.currentSelectRole.companyPartnerType
          : '',
      })
    },
    purchase(index) {
      this.showPurchase = true
      this.selectedProduct = this.list[index]
    },
    getIntValue(value, defaultValue) {
      return typeof value === 'string'
        ? parseInt(value)
        : typeof value === 'number'
        ? value
        : defaultValue
    },
    //获取商品详情
    async getGoodDetail(skuCode) {
      const params = {
        customerCode: this.customerInfo.custCode,
        skuCode: skuCode,
      }
      const { respCode, respData } = await getGoodsInfo(params)
      if (respCode === '10000') {
        return respData
      }
    },
    toCancle() {
      this.$nextTick(() => {
        this.showPurchase = false
      })
    },
    //装号状态验证
    validStatus() {
      return new Promise((resolve, reject) => {
        if (
          this.customerInfo.status !== StatusConst.common &&
          this.customerInfo.status !== StatusConst.freeze
        ) {
          Toast.fail(
            `当前账户${
              CustomerStatusConst[this.customerInfo.status]
            },暂无法下单，请稍后再试，如有疑问请联系客服人员！`
          )
          return
        } else if (this.customerInfo.roleBlack === 'Y') {
          Toast.fail(
            '当前账户为黑名单,暂无法下单，请稍后再试，如有疑问请联系客服人员！'
          )
          return
        } else if (this.customerInfo.paymentStatus === 'N') {
          return Toast.fail(
            `当前账户回款未达标,暂无法下单，请先充值，如有疑问请联系客服人员！`
          )
        } else {
          resolve()
        }
      })
    },
    //去购买
    async toBuy({ id, count, buyType }) {
      await this.validStatus()
      const saleAccount = this.accountTypes[0]
      const goodDetail = this.selectedProduct
      const params = {
        saleAccType: saleAccount.value,
        custId: this.customerInfo.custCode,
        saleAccName: saleAccount.label,
        yhno: this.yhno ? this.yhno : '',
        saleChannel: this.saleChannel || '',
        skusHome: {
          totalPrice: 0,
          totalCount: 0,
          promotionSkus: [],
          coupons: [],
        },
        skusCloud: {
          totalPrice: 0,
          totalCount: 0,
          promotionSkus: [],
          coupons: [],
        },
      }
      this.$set(goodDetail, 'skuCount', count)
      this.showPurchase = false
      if (buyType === 'home') {
        params.skusHome.totalCount = count
        params.skusHome.totalPrice = count * goodDetail.price
        params.skusHome.promotionSkus.push({
          skus: [goodDetail],
        })
        setStore({
          name: 'createOrder',
          content: {
            cart: params,
          },
          type: 'session',
        })
        this.$router.push({
          name: 'createPurchase',
        })
      } else if (buyType === 'cloud') {
        params.skusCloud.totalCount = count
        params.skusCloud.totalPrice = count * goodDetail.price
        params.skusCloud.promotionSkus.push({
          skus: [goodDetail],
        })
        setStore({
          name: 'createOrder',
          content: {
            cart: params,
          },
          type: 'session',
        })
        this.$router.push({
          name: 'cloudCreate',
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@border-radius: 8px;

.recommend-container {
  margin-top: 16px;
  margin-bottom: 55px;
  .recommend-title {
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    position: relative;
    padding-left: 11px;
    &::after {
      display: block;
      content: '';
      width: 3px;
      height: 12px;
      background: #ff264a;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

::v-deep .van-grid-item {
  margin-top: 12px;
  border-radius: @border-radius;
  overflow: hidden;
  .van-grid-item__content {
    padding: 0;
  }
}
//
///deep/ .van-grid-item:nth-child(odd) {
//  padding-right: 4.5px;
//}
//
///deep/ .van-grid-item:nth-child(even) {
//  padding-left: 4.5px;
//}

/deep/ .van-popup__close-icon--top-right {
  top: 12px;
}

.van-divider /deep/ {
  margin-top: 6px;
  margin-left: 50px;
  margin-right: 50px;
}

.van-divider::after /deep/,
.van-divider::before /deep/ {
  border-color: #dddddd;
}
</style>
