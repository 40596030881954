<template>
  <div class="purchase-card">
    <div class="title">
      去购买
      <van-image
        :src="require('../../assets/home/close.svg')"
        alt=""
        @click="closePop"
        class="icon-close"
      />
    </div>
    <div class="purchase-content">
      <div class="purchase-card-info">
        <van-image
          style="min-width: 90px"
          width="90px"
          height="90px"
          radius="4px"
          class="purchase-card-image"
          :src="thumb"
        />
        <div class="purchase-card-desc">
          <div class="purchase-card-title">{{ title }}</div>
          <div class="purchase-card-price">¥{{ price }}</div>
          <div class="purchase-card-unit">
            已选择:“{{ count }}{{ level2Obj[unit] }}”
          </div>
        </div>
      </div>
      <div class="purchase-method">
        <div class="purchase-method-text">下单方式</div>
        <span
          class="purchase-method-item"
          v-if="buyHome && saleChannel === 'city'"
          :class="{ 'purchase-method-item-active': active == 'home' }"
          @click="active = 'home'"
        >
          订货到家
        </span>
        <span
          class="purchase-method-item"
          v-if="buyCloud && saleChannel === 'cloud'"
          :class="{ 'purchase-method-item-active': active == 'cloud' }"
          @click="active = 'cloud'"
        >
          云仓备货
        </span>
      </div>
      <div class="purchase-quantity">
        <span>
          <span class="purchase-quantity-text">购买数量</span>
          <span class="purchase-quantity-text-tip" v-if="limit && limit.limit">
            {{ '(' + limit.text + ')' }}
          </span>
        </span>
        <van-stepper
          v-model="count"
          :min="1"
          :max="limit && !limit.period ? limit.limit : undefined"
          integer
          :step="1"
          button-size="28px"
          @blur="changeCount()"
        />
      </div>
    </div>
    <div class="purchase-button">
      <van-button
        color="#B94B4B"
        class="btn-large"
        text="立即购买"
        block
        round
        @click="forBuy"
      ></van-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SaleChannelTypesConst } from '@/const/common'
import { getLimitInfo } from '@/utils/utils'

export default {
  name: 'PurchaseCard',
  props: {
    id: {
      type: String,
      required: '',
    },
    thumb: {
      type: String,
    },
    price: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
    },
    unit: {
      type: String,
      default: '盒',
    },
    buyHome: {
      type: Boolean,
      default: true,
    },
    buyCloud: {
      type: Boolean,
      default: true,
    },
    saleChannel: {
      type: String,
      default: SaleChannelTypesConst.cloud,
    },
    goodsInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      count: 0,
      active:
        this.saleChannel === SaleChannelTypesConst.cloud ? 'cloud' : 'home',
    }
  },
  computed: {
    ...mapGetters(['getUnitsList']),
    level2Obj() {
      // 规箱单位转换
      return this.getUnitsList.reduce((result, item) => {
        result[item.value] = item.label
        return result
      }, {})
    },
    limit() {
      return getLimitInfo(this.goodsInfo)
    },
  },
  methods: {
    forBuy() {
      this.$emit('buy', {
        id: this.id,
        count: this.count,
        buyType: this.active,
      })
    },
    closePop() {
      this.$emit('cancle')
    },
    //手动输入数量校验
    changeCount() {
      // @blur='changeCount()'
      if (this.start) {
        this.count = parseInt(this.count / this.start) * this.start
      }
    },
  },
}
</script>

<style lang="less" scoped>
.purchase-card {
  padding: 15px 10px 0 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .title {
    position: relative;
    text-align: center;
    height: 22px;
    line-height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #323233;

    .icon-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 22px;
      height: 22px;
    }
  }

  .purchase-content {
    flex: 1;
    overflow: scroll;
    margin-top: 32px;
  }
}

.purchase-card-info {
  display: flex;
}

.purchase-card-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.purchase-card-image {
  border: 1px solid @split-color;
  margin-right: 12px;
}

.purchase-card-title {
  font-size: 17px;
  font-weight: 500;
  color: @text-color-title;
  line-height: 21px;
}

.purchase-card-price {
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: @primary-color;
  margin: 15px 0 6px;
  line-height: 25px;
}

.purchase-card-price::first-letter {
  font-size: 12px;
  margin-right: 4px;
}

.purchase-card-unit {
  height: 21px;
  font-size: 15px;
  font-weight: 400;
  color: @text-color-tip;
  line-height: 21px;
}

.purchase-method {
  margin-top: 30px;

  .purchase-method-text {
    height: 21px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    margin-bottom: 8px;
  }
}

.purchase-method-item {
  display: inline-block;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  font-weight: 400;
  cursor: default;
  padding: 6px 18px 5px 18px;
  margin-right: 15px;
}

.purchase-method-item-active {
  border: 1px solid @border-color-low;
  color: @primary-color;
}

.purchase-quantity {
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .purchase-quantity-text {
    height: 21px;
    font-size: 15px;
    font-weight: 400;
    color: @text-color-title;
    line-height: 21px;
  }

  .purchase-quantity-text-tip {
    padding-left: 5px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: @text-color-tip;
    line-height: 16px;
  }
}

.purchase-button {
  padding: 38px 38px 34px 38px;
  margin-left: -15px;
  margin-right: -10px;
}

/deep/ .van-card__thumb {
  margin-right: 12px;
}

/deep/ .van-card__title {
  height: 21px;
  font-size: 17px;
  font-weight: 500;
  color: @text-color-title;
  line-height: 21px;
}
</style>
