export const CustomerStatusConst = {
  common: '正常',
  freeze: '冻结',
  black: '黑名单',
  change: '变更中',
  clear: '已清退',
  supplier_audit: '供货商审核',
  company_audit: '公司审核',
  wait_signed: '待签约',
  signing: '签约中',
  no_pass: '审核未通过',
}
//状态
export const StatusConst = {
  //正常
  common: 'common',
  //冻结
  freeze: 'freeze',
  //黑名单
  black: 'black',
  //变更中
  change: 'change',
  //已清退
  clear: 'clear',
  //供货商审核
  supplierAudit: 'supplier_audit',
  //公司审核
  companyAudit: 'company_audit',
  //待签约
  waitSigned: 'wait_signed',
  //签约中
  signing: 'signing',
  //审核未通过
  noPass: 'no_pass',
}

//  C-公司，S-个体，P-个人
export const subjectTypeList = [
  {
    type: 'C',
    name: '企业主体',
    info: '采购货品、卖货',
    image: require('@/assets/selectRole/company.png'),
    miniImg: require('@/assets/selectRole/role-mini/star.png'),
    model: ['云仓备货'],
    borderColor: '#64CAAC',
    bgColor: '#EAFFFA',
    arrowBg: '#77DABD',
    subjectColor: '#333',
    tagColor: '#FE3245',
  },
  {
    type: 'S',
    name: '个体工商户',
    info: '采购货品、卖货',
    image: require('@/assets/selectRole/company.png'),
    miniImg: require('@/assets/selectRole/role-mini/star.png'),
    model: ['云仓备货'],
    borderColor: '#64CAAC',
    bgColor: '#EAFFFA',
    arrowBg: '#77DABD',
    subjectColor: '#333',
    tagColor: '#FE3245',
  },
  {
    type: 'P',
    name: '个人主体',
    info: '采购货品、卖货',
    image: require('@/assets/selectRole/person.png'),
    miniImg: require('@/assets/selectRole/role-mini/star.png'),
    model: ['云仓备货'],
    borderColor: '#64CAAC',
    bgColor: '#EAFFFA',
    arrowBg: '#77DABD',
    subjectColor: '#333',
    tagColor: '#FE3245',
  },
  {
    type: 'I',
    name: '进件主体',
    info: '管理代理、享受收益',
    image: require('@/assets/selectRole/in-come.png'),
    miniImg: require('@/assets/selectRole/role-mini/star.png'),
    model: ['云仓备货'],
    borderColor: '#64CAAC',
    bgColor: '#EAFFFA',
    arrowBg: '#77DABD',
    subjectColor: '#333',
    tagColor: '#FE3245',
  },
]
// 未审核通过状态 审核中  未通过 签约中 等待签约
export const notApprovedStatus = [
  'company_audit',
  'no_pass',
  'supplier_audit',
  'reject',
  'wait_signed',
  'signing',
  'unsigned',
]
// 未审核通过状态 显示文案
export const notApprovedStatusText = {
  company_audit: '审核中',
  no_pass: '审核失败',
  supplier_audit: '审核中',
  reject: '审核失败',
  wait_signed: '签约中',
  signing: '签约中',
  unsigned: '未签约',
}
