/**
 * 业务状态码
 */
export const BizState = {
  success: '10000',
}

/**
 * 销售渠道key
 */
export const SaleChannelKey = 'saleChannel'
export const SaleModeKey = 'saleMode'
export const SaleChannelEnum = {
  cloud: 'cloud',
  bcompany: 'cloud',
  boom: 'boom',
}
export const SaleModeEnum = {
  cloud: 'cloud',
  bcompany: 'bcompany',
  boom: 'cloud',
}
/**
 * 销售渠道常量
 */
export const SaleChannelTypesConst = {
  // 云店
  cloud: 'cloud',
  // 一人一城
  city: 'city',
}

/**
 * 客户等级/客户类型
 */
export const CustomerLevelConst = {
  // 星级代理商
  star: 'star',
  // 代理商
  agent: 'agent',
  // 经销商
  distributor: 'distributor',
  personal: 'personal',
  // 城主
  lord: 'lord',
  // 店主
  shopkeeper: 'shopkeeper',
  headOffice: 'head_office',
  branchOffice: 'branch_office',
  founder: 'founder',
  coFounder: 'co_founder',
  personalFounder: 'personal_founder',
}

/**
 * 客户等级文字
 */
export const CustomerLevelLabelConst = {
  [CustomerLevelConst.star]: '星级代理商',
  [CustomerLevelConst.agent]: '代理商',
  [CustomerLevelConst.distributor]: '经销商',
  [CustomerLevelConst.personal]: '个人股东',
  [CustomerLevelConst.lord]: '城主',
  [CustomerLevelConst.shopkeeper]: '店主',
  head_office: '总公司',
  branch_office: '分公司',
  founder: '总裁',
  co_founder: '总监',
  personal_founder: '小股东',
}

/**
 * 订单来源渠道
 */
export const orderSourceChannel = {
  B_END: 'B端',
  C_END: 'C端',
}

/**
 * 订单来源
 */
export const orderSource = {
  /**
   * 订单来源枚举
   */
  CREATE_ORDER: '后台建单',
  IMPORT_ORDER: '后台导单',

  /**
   * 爆品模式下的自主下单和客户下单
   */
  MINE_ORDER: '自主下单',
  CUSTOMER_ORDER: '客户下单',

  TRANSFER_ORDER: '客户转单',
  WEIMOB_ORDER: '微盟',

  CLOUD_DELIVERY: '云仓提货',
  CLOUD_DELIVERY_TRANSFER: '云仓提货转单',
  AFTER_SALES: '售后换货',
  AFTER_SALES_SUPPLEMENTARY: '售后补单',

  /**
   * 分公司模式下的C端转单,记录本层级和链路的订单
   */
  BCOMPANY_C_END_TRANSFER: 'C端转单',
  BCOMPANY_CUSTOMER_TRANSFER: '客户转单',

  /**
   * 分公司模式B端下单,记录代理在B端下单的自己层级和链路的订单
   */
  BCOMPANY_MINE_ORDER: '自主下单',
  BCOMPANY_CUSTOMER_ORDER: '客户下单',

  /**
   * 分公司模式下生成链路的订单,导入的当前层级为客服导单,链路帮忙生成的为系统导单
   */
  BCOMPANY_CUSTOMER_IMPORT: '客服导单',
  BCOMPANY_SYSTEM_IMPORT: '系统导单',
}

/**
 * 订单发货状态
 */
export const orderDeliverStatus = {
  PENDING_SEND: '待发货',
  PART_SENDED: '部分发货',
  ALL_SENDED: '全部发货',
}
