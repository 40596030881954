export const jmRouters = [
  // 客户列表
  {
    name: 'customerList',
    path: '/customerList',
    component: () => import('@/views/customerList/customerListNew.vue'),
    meta: {
      title: '客户列表',
    },
  },
  {
    name: 'customerToDo',
    path: '/customerToDo',
    component: () => import('@/views/customerList/customerToAudit.vue'),
    meta: {
      title: '待处理详情',
    },
  },
  {
    name: 'customerDetail',
    path: '/customerDetail/:id/:yhno',
    component: () => import('@/views/customerList/customerDetail.vue'),
    meta: {
      title: '客户详情',
    },
  },
  {
    name: 'customerBalance',
    path: '/customerBalance/:custSaleChannelId/:customerId/:customerCode/:yhno/:type',
    component: () => import('@/views/customerList/customerBalance.vue'),
    meta: {
      title: '我的钱包',
    },
  },
  {
    name: 'customerCoupon',
    path: '/customerCoupon/:customerId/:customerCode/:customerSubjectCode/:yhno/:saleChannel/:custLevel',
    component: () => import('@/views/customerList/customerCoupon.vue'),
    meta: {
      title: '客户优惠券',
    },
  },
  {
    name: 'customerPickUpCoupon',
    path: '/customerPickUpCoupon/:customerId/:customerCode/:customerSubjectCode/:yhno/:saleChannel/:custLevel/:customerType',
    component: () => import('@/views/customerList/customerPickUpCoupon.vue'),
    meta: {
      title: '客户提货券',
    },
  },
  {
    name: 'customerDaily',
    path: '/customerDaily/:customerId/:yhno',
    component: () => import('@/views/customerList/customerDaily.vue'),
    meta: {
      title: '客户流水单',
    },
  },
  // 商品
  {
    name: 'goodsDetail',
    path: '/goodsDetail',
    component: () => import('@/views/goodsDetail/index.vue'),
    meta: {
      title: '商品详情',
    },
  },

  {
    name: 'customerAuth',
    path: '/customerAuth/:id',
    component: () => import('@/views/customerList/customerAuth.vue'),
    meta: {
      title: '授权信息',
    },
  },
  {
    name: 'customerToDo',
    path: '/customerToDo/:yhno/:auditId/:custCode',
    component: () => import('@/views/customerList/customerToDo.vue'),
    meta: {
      title: '待处理详情',
    },
  },
  {
    name: 'cashOut',
    path: '/cashOut',
    component: () => import('@/views/collection/cashOut'),
    meta: {
      title: '提现',
    },
  },
  {
    name: 'shoppingCart',
    path: '/shoppingCart',
    component: () => import('@/views/shoppingCart/index.vue'),
    meta: {
      title: '购物车',
      depth: 1,
    },
  },
  {
    name: 'postageBalance',
    path: '/postageBalance',
    component: () => import('@/views/postageBalance/index'),
    meta: {
      title: '邮费余额',
    },
  },
  {
    name: 'postageBalanceList',
    path: '/postageBalanceList',
    component: () => import('@/views/postageBalance/postageBalanceList'),
    meta: {
      title: '邮费明细',
    },
  },
  {
    name: 'postageList',
    path: '/postageList',
    component: () => import('@/views/postageCheck/postageList.vue'),
    meta: {
      title: '邮费核对列表',
    },
  },
  {
    name: 'postageCheck',
    path: '/postageCheck',
    component: () => import('@/views/postageCheck/postageCheck.vue'),
    meta: {
      title: '邮费核对详情',
    },
  },

  // 商品管理
  {
    name: 'goodsManage',
    path: '/goodsManage',
    component: () => import('@/views/goodsManage/index.vue'),
    meta: {
      title: '商品管理',
    },
  },
  {
    name: 'warehouseInOutDetail',
    path: '/warehouseInOutDetail',
    component: () => import('@/views/goodsManage/warehouseInOutDetail.vue'),
    meta: {
      title: '出入库明细',
    },
  },
  {
    name: 'adjustWarehouse',
    path: '/adjustWarehouse',
    component: () => import('@/views/goodsManage/adjustWarehouse.vue'),
    meta: {
      title: '调整库存',
    },
  },
  {
    name: 'addGoodsToWarehouse',
    path: '/addGoodsToWarehouse',
    component: () => import('@/views/goodsManage/addGoods.vue'),
    meta: {
      title: '添加商品',
    },
  },

  // 签约
  {
    name: 'contractList',
    path: '/contractList',
    component: () => import('@/views/contract/contractList.vue'),
    meta: {
      title: '电子签约',
    },
  },
  {
    name: 'contractDetail',
    path: '/contractDetail/:id/:subjectId',
    component: () => import('@/views/contract/contractDetail.vue'),
    meta: {
      title: '签约详情',
    },
  },
  {
    name: 'notContractDetail',
    path: '/notContractDetail/:id/:subjectId',
    component: () => import('@/views/contract/contractDetail.vue'),
    meta: {
      title: '待签约详情',
    },
  },
  {
    name: 'contractSuccess',
    path: '/contractSuccess',
    component: () => import('@/views/contract/contractSuccess.vue'),
    meta: {
      title: '签约成功',
    },
  },

  {
    name: 'resetPassword',
    path: '/resetPassword',
    component: () => import('@/views/login/resetPassword.vue'),
    meta: {
      title: '重置密码',
    },
  },

  // 我的卡券
  {
    name: 'myCoupon',
    path: '/coupon/myCoupon',
    component: () => import('@/views/coupon/myCoupon.vue'),
    meta: {
      title: '我的卡券',
    },
  },
  {
    name: 'couponCenter',
    path: '/coupon/couponCenter',
    component: () => import('@/views/coupon/couponCenter.vue'),
    meta: {
      title: '领券中心',
    },
  },
  {
    name: 'useNow',
    path: '/coupon/useNow',
    component: () => import('@/views/coupon/useNow.vue'),
    meta: {
      title: '使用提货券',
      depth: 1,
    },
  },

  {
    name: 'myInvitation',
    path: '/myInvitation',
    component: () => import('@/views/myInvitation/index.vue'),
    meta: {
      title: '我的邀请',
    },
  },
  {
    name: 'new-myInvitation',
    path: '/new-myInvitation',
    component: () => import('@/views/newModel/myInvitation/index.vue'),
    meta: {
      title: '我的邀请',
    },
  },
  // 搜索
  {
    name: 'search',
    path: '/search',
    component: () => import('@/views/search/index'),
    meta: {
      title: '搜索',
    },
  },
  {
    name: 'search-result',
    path: '/search-result',
    component: () => import('@/views/search/searchResult'),
    meta: {
      title: '搜索结果',
      depth: 1,
    },
  },
  // 我的活动
  {
    name: 'myEvent',
    path: '/myEvent/event',
    component: () => import('@/views/myEvent/event'),
    meta: {
      title: '我的活动列表',
    },
  },
  {
    name: 'screensParticulars',
    path: '/myEvent/screensParticulars',
    component: () => import('@/views/myEvent/screensParticulars'),
    meta: {
      title: '活动详情',
    },
  },
  {
    name: 'superParticulars',
    path: '/myEvent/superParticulars',
    component: () => import('@/views/myEvent/superParticulars'),
    meta: {
      title: '活动详情',
    },
  },
  {
    name: 'distributableBalanceDetail',
    path: '/myEvent/distributableBalanceDetail',
    component: () => import('@/views/myEvent/distributableBalanceDetail'),
    meta: {
      title: '可分配余额明细',
    },
  },
  // 经销商活动
  {
    name: 'dealersEvent',
    path: '/dealersEvent/index',
    component: () => import('@/views/dealersEvent/index'),
    meta: {
      title: '经销商活动',
    },
  },
  {
    name: 'eventParticulars',
    path: '/dealersEvent/eventParticulars',
    component: () => import('@/views/dealersEvent/eventParticulars'),
    meta: {
      title: '查看参与人',
    },
  },
  {
    name: 'returnParticulars',
    path: '/dealersEvent/returnParticulars',
    component: () => import('@/views/dealersEvent/returnParticulars'),
    meta: {
      title: '查看参与人',
    },
  },
  {
    name: 'screensParticulars',
    path: '/dealersEvent/screensParticulars',
    component: () => import('@/views/dealersEvent/screensParticulars'),
    meta: {
      title: '活动详情',
    },
  },
  {
    name: 'returnScreensParticulars',
    path: '/dealersEvent/returnScreensParticulars',
    component: () => import('@/views/dealersEvent/returnScreensParticulars'),
    meta: {
      title: '活动详情',
    },
  },
  // 客户地址
  {
    name: 'addressManageList',
    path: '/addressManage/addressList',
    component: () => import('@/views/addressManage/addressList'),
    meta: {
      title: '地址管理',
    },
  },
  {
    name: 'addressManageEdit',
    path: '/addressManage/addressEdit',
    component: () => import('@/views/addressManage/addressEdit'),
    meta: {
      title: '编辑地址',
    },
  },
  {
    name: 'addressManageAdd',
    path: '/addressManage/addressAdd',
    component: () => import('@/views/addressManage/addressEdit'),
    meta: {
      title: '新增地址',
    },
  },

  // 运费模板
  {
    name: 'freightTemplateList',
    path: '/freightTemplateManage/freightTemplateList',
    component: () =>
      import('@/views/freightTemplateManage/freightTemplateList.vue'),
    meta: {
      title: '运费模板',
    },
  },
  {
    name: 'addFreightTemplate',
    path: '/freightTemplateManage/addFreightTemplate',
    component: () =>
      import('@/views/freightTemplateManage/freightTemplateDetail.vue'),
    meta: {
      title: '新增运费模板',
    },
  },
  {
    name: 'editFreightTemplate',
    path: '/freightTemplateManage/editFreightTemplate',
    component: () =>
      import('@/views/freightTemplateManage/freightTemplateDetail.vue'),
    meta: {
      title: '编辑运费模板',
    },
  },
  {
    name: 'associatedGoods',
    path: '/freightTemplateManage/associatedGoods',
    component: () =>
      import('@/views/freightTemplateManage/associatedGoods.vue'),
    meta: {
      title: '关联商品',
    },
  },
  {
    name: 'addGoods',
    path: '/freightTemplateManage/addGoods',
    component: () =>
      import('@/views/freightTemplateManage/unBindTemplateGoods.vue'),
    meta: {
      title: '添加商品',
    },
  },

  {
    name: 'inviteAgent',
    path: '/inviteAgent',
    component: () => import('@/views/inviteAgent/index.vue'),
    meta: {
      title: '注册',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'inviteAgent-newMode',
    path: '/inviteAgent-newMode',
    component: () => import('@/views/newModel/inviteAgent/index.vue'),
    meta: {
      title: '注册',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'inviteAgentSuccess',
    path: '/inviteAgent/success',
    component: () => import('@/views/inviteAgent/success.vue'),
    meta: {
      title: '注册成功',
      depth: 1,
    },
  },
  {
    name: 'new-inviteAgentSuccess',
    path: '/new-inviteAgent/success',
    component: () => import('@/views/newModel/inviteAgent/success.vue'),
    meta: {
      title: '注册成功',
      depth: 1,
    },
  },
  {
    name: 'privacyPolicy',
    path: '/inviteAgent/privacyPolicy',
    component: () => import('@/views/inviteAgent/privacyPolicy.vue'),
    meta: {
      title: '隐私协议',
      depth: 3,
    },
  },
  {
    name: 'reduction',
    path: '/reduction',
    component: () => import('@/views/promotion/reduction.vue'),
    meta: {
      title: '满减凑单',
    },
  },
  {
    name: 'fullGift',
    path: '/fullGift',
    component: () => import('@/views/promotion/fullGift'),
    meta: {
      title: '满赠凑单',
    },
  },
  {
    name: 'additional',
    path: '/additional',
    component: () => import('@/views/promotion/additional'),
    meta: {
      title: '换购商品',
    },
  },
  {
    name: 'applyForUpgrade',
    path: '/applyForUpgrade',
    component: () => import('@/views/applyForUpgrade/index'),
    meta: {
      title: '申请升级',
    },
  },
  {
    name: 'submitResult',
    path: '/submitResult',
    component: () => import('@/views/applyForUpgrade/submit-result'),
    meta: {
      title: '申请升级',
    },
  },
  {
    name: 'loginStatus',
    path: '/loginStatus',
    component: () => import('@/views/login/loginStatus'),
    meta: {
      title: '',
    },
  },
  {
    name: 'myAuth',
    path: '/myAuth',
    component: () => import('@/views/customerList/customerAuth'),
    meta: {
      title: '授权证书',
    },
  },
  {
    name: 'makeAuth',
    path: '/makeAuth',
    component: () => import('@/views/customerList/makeAuth'),
    meta: {
      title: '制作授权证书',
    },
  },
  {
    name: 'unauthorized',
    path: '/unauthorized',
    component: () => import('@/views/login/components/unauthorized'),
    meta: {
      title: '实名认证',
    },
  },
  {
    name: 'reportDetail',
    path: '/reportDetail',
    component: () => import('@/views/reportManage/reportDetail'),
    meta: {
      title: '运营报告',
    },
  },
]
