<template>
  <div class="home">
    <div class="top-container">
      <search-input class="search-input" />
      <banner ref="banner" v-if="show" />
    </div>

    <div class="home-content">
      <custBar @load="showIcon" v-if="custVisible" />

      <!--      <van-row type="flex" justify="center" class="middle-row">-->
      <!--        <van-col-->
      <!--          :span="12"-->
      <!--          v-for="(item, index) in fastEnterList"-->
      <!--          :key="index"-->
      <!--          class="module-entry"-->
      <!--          @click="linkto(item.link)"-->
      <!--        >-->
      <!--          <van-image class="module-icon" :src="getImg(item.icon)"></van-image>-->
      <!--        </van-col>-->
      <!--      </van-row>-->
      <div
        class="quick-link-box d-flex flex-wrap"
        v-if="categoryList.length > 1"
      >
        <div
          class="category-link"
          v-for="(item, index) in categoryList.filter(
            (item, index) => index < 8
          )"
          :key="index"
          @click="categoryTo(item.id)"
        >
          <div>
            <img class="category-icon" :src="item.categoryImage" alt="" />
          </div>
          <div class="font-12 main-color-title">{{ item.categoryName }}</div>
        </div>
      </div>
      <div class="quick-link-button">
        <!--        <div-->
        <!--          v-for="(item, index) in fastEnterList"-->
        <!--          :key="index"-->
        <!--          class="quick-link"-->
        <!--          @click="linkto(item.link)"-->
        <!--        >-->
        <!--          <van-image class="quick-icon" :src="getImg(item.icon)"></van-image>-->
        <!--        </div>-->

        <div
          class="quick-link-item"
          :class="{ big: !saleMode && index === 0, cloud: !saleMode }"
          @click="linkto(item.link)"
          v-for="(item, index) in fastEnterList"
          :key="index"
        >
          <div class="left">
            <div class="title">{{ item.name }}</div>
            <div class="info">{{ item.info }}</div>
            <div class="btn">
              {{ item.btnName }}
              <van-icon
                @click="linkto(item.link)"
                class="right"
                :name="require('@/assets/boom/right-arrow-red.png')"
                size="8px"
              />
            </div>
          </div>
          <van-icon class="right" :name="getImg(item.icon)" />
        </div>
      </div>
      <new-recommend v-if="show" />
    </div>
    <common-tabbar active="home" style="z-index: 999" />
    <shopping-cart-icon v-if="false" style="z-index: 999"></shopping-cart-icon>
    <invite-shopkeeper
      :visible.sync="showInvitePopup"
      v-if="showInvitePopup"
    ></invite-shopkeeper>
  </div>
</template>

<script>
// @ is an alias to /src
import custBar from '@/views/home/custBar'
import CommonTabbar from '@/components/CommonTabbar.vue'
import Banner from '@/views/home/banner.vue'
import NewRecommend from '@/views/home/newRecommend.vue'
import InviteShopkeeper from '@/views/tabbar/components/invite-shopkeeper'
import ShoppingCartIcon from '@/components/ShoppingCartIcon'
import { getHomeFastEnter } from '@/service/operatingInstructions'
import {
  CustomerLevelConst,
  SaleChannelKey,
  SaleChannelTypesConst,
} from '@/const/common'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { getCategoryNewMode } from '@/views/tabbar/api/goods'
import searchInput from '@/views/tabbar/components/searchInput.vue'

export default {
  name: 'HomeView',
  components: {
    searchInput,
    custBar,
    CommonTabbar,
    Banner,
    NewRecommend,
    ShoppingCartIcon,
    InviteShopkeeper,
  },
  data() {
    return {
      show: false,
      saleChannel: '',
      custVisible: false,
      custLevelConst: CustomerLevelConst,
      showInvitePopup: false,
      globalList: [],
      imgActice: 1,
      moduleId: 0,
      fastEnterList: [],
      categoryList: [],
    }
  },
  computed: {
    ...mapGetters(['customerInfo', 'getAccountType', 'saleMode']),
    isProduct() {
      return window.location.origin === 'https://dmsmb.saselomo.com'
    },
  },
  async created() {
    if (
      this.customerInfo.acctType === 'sub' &&
      this.customerInfo.custJurisdiction !== 'all'
    ) {
      await this.$router.push({ path: '/mine-subAcct' })
    }
    await this.getFastEnter()
    await this.getDict()
    this.saleChannel = sessionStorage.getItem(SaleChannelKey)
    if (
      this.saleChannel &&
      this.saleChannel === SaleChannelTypesConst.city &&
      this.customerInfo.custLevel === 'shopkeeper'
    ) {
      this.custVisible = true
    } else {
      this.showIcon()
    }
    if (!this.saleMode) return
    const res = await getCategoryNewMode()
    if (res.ok) {
      const all = res.respData[0]
      res.respData.shift()
      if (res.respData.length < 8) {
        res.respData.push(all)
      } else {
        res.respData[7] = all
      }
      this.categoryList = res.respData
    }
  },
  methods: {
    getImg(icon) {
      try {
        return require(`@/assets/home/${icon}`)
      } catch {
        return ''
      }
    },
    async getFastEnter() {
      const res = await getHomeFastEnter()
      if (res.respCode === '10000') {
        this.accountBalanceInfo = res.respData
      }
      this.fastEnterList = [...(res.respData || [])]
      // this.fastEnterList = [this.fastEnterList[0], this.fastEnterList[1], this.fastEnterList[2], this.fastEnterList[3]]
    },
    //获取字典
    async getDict() {
      if (_.isEmpty(this.getAccountType)) {
        await this.$store.dispatch('CustomerType')
      }
    },
    showIcon() {
      //yhno获取后为，放开展示调用yhno的组件展示
      this.show = true
    },
    onFocusSearch() {
      this.$router.push({ path: '/search' })
    },
    linkto(url) {
      // console.log('跳转到公司大促列表页面')
      if (url === '/home') {
        this.getFastEnter()
        this.$refs.banner.getBanner()
      } else {
        this.$router.push(url)
      }
    },
    categoryTo(id) {
      this.$router.push({
        path: '/new-goods',
        query: { id },
      })
    },
    onClickInvite() {
      if (this.customerInfo.custLevel === CustomerLevelConst.lord) {
        this.showInvitePopup = true
      } else {
        this.$router.push({ path: '/myInvitation' })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  .operatingInstructionsBox {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 5;
    .imgBox {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .nextBtn {
        position: absolute;
        opacity: 0;
      }
    }
  }
}
.top-container {
  background: url('@/assets/home/top-bg.png') no-repeat 0 -26px;
  background-size: 100% 100%;
  padding: 12px 12px 0;
}
.home-content {
  padding: 0 12px;
  margin-bottom: 150px;
  overflow-x: hidden;
  position: absolute;
  z-index: 99;

  .search-input {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 12px;
    box-sizing: border-box;

    /deep/ .van-field__left-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .van-search__content.van-search__content--round {
      background-color: #ffffff;
    }
    .van-search__action {
      line-height: 100%;

      .msg-icon {
        font-size: 24px;
      }

      .van-info {
        background: @primary-color;
      }
    }
  }

  .quick-link-box {
    background: #ffffff;
    box-shadow: @box-shadow;
    border-radius: 8px;
    margin-top: 12px;
    padding: 2px 12px 12px;
    flex-wrap: wrap;
    &.link {
      justify-content: space-between;
    }
    .quick-link {
      margin-top: 10px;
      .quick-icon {
        width: 158px;
        height: 95px;
      }
    }
    .category-link {
      margin-top: 10px;
      width: calc(20% - 19.2px);
      text-align: center;
      margin-right: 24px;
      &:nth-of-type(5n) {
        margin-right: 0;
      }
      .category-icon {
        width: 46px;
        height: 46px;
        background: #f6f6f6;
        border-radius: 8px;
      }
      .main-color-title {
        min-width: 50px;
        margin-left: -2px;
      }
    }
  }

  .quick-link-button {
    background: url('@/assets/home/bcompany-bg.png');
    background-size: 100% 100%;
    box-shadow: @box-shadow;
    border-radius: 8px;
    margin-top: 12px;
    padding: 12px;
    justify-content: space-between;
    overflow: hidden;
    .quick-link-item {
      height: 88px;
      width: 158px;
      margin-right: 10px;
      box-sizing: border-box;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      box-shadow: 0 0 4px 0 rgba(219, 219, 219, 0.1);
      border-radius: 8px;
      display: flex;
      float: left;
      padding: 12px 0 12px 12px;
      &.big {
        height: 185px;
        flex-direction: column;
        > .van-icon {
          width: max-content;
          margin-top: 6px;
          margin-left: auto;
          margin-right: 4px;
          .van-icon__image {
            width: 110px;
            height: 100px;
          }
        }
      }
      &.cloud {
        &:nth-of-type(3) {
          margin-right: 0;
          margin-bottom: 10px;
        }
        &:nth-of-type(4) {
          margin-right: 10px;
          margin-bottom: 0;
        }
        &:nth-of-type(5) {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(-n + 2) {
        margin-bottom: 10px;
      }
      .left {
        flex: 1;

        .title {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          line-height: 20px;
        }

        .info {
          font-size: 10px;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-bottom: 10px;
          position: absolute;
        }

        .btn {
          width: max-content;
          height: 20px;
          border-radius: 10px;
          border: 1px solid #ff0845;
          padding: 3px 3px 3px 6px;
          box-sizing: border-box;
          font-size: 10px;
          font-weight: 400;
          color: #ff0845;
          line-height: 14px;
          text-align: center;
          margin-top: 24px;
          .van-icon {
            margin-right: 0;
          }
        }
      }

      .right {
        margin-right: 0;
      }
      > .van-icon {
        font-size: 70px;
        .van-icon__image {
          height: unset;
        }
      }
    }
  }

  .module-entry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    flex: 0 0 48%;
    position: relative;

    .module-icon {
      margin-bottom: 6px;
      width: 158px;
      height: 95px;
    }

    .badge {
      position: absolute;
      top: -10px;
      left: 60%;
      background: url('../../assets/home/promotion.gif') no-repeat;
      background-size: 100% 100%;
      width: 32px;
      height: 18px;
      z-index: 99;
    }

    .module-title {
      text-align: center;
      height: 19px;
      font-size: 13px;
      font-weight: 400;
      color: #555555;
      line-height: 19px;
    }
  }
}
</style>
